.container {
  width: 300px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-60%);
  border-radius: 2rem;
  padding: 1.5rem 1.75rem;
  background-color: #0089b6;
  user-select: none;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.container .beach-logo {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  stroke: #FFFFFF;
  fill: #FFFFFF;
  margin-right: 1rem;
  border-radius: 50%;
}
.container .text {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #FFFFFF;
}
.container .logo-container {
  position: absolute;
  background-color: #0089b6;
  top: 90%;
  right: 50%;
  transform: translateX(50%);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 0px 25px 5px;
}
.container .logo {
  height: 20px;
}

.mobile {
  transform: translateX(-50%) translateY(-60%) scale(0.75);
}