/* @font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9wf6kc');
  src:  url('fonts/icomoon.eot?9wf6kc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9wf6kc') format('truetype'),
    url('fonts/icomoon.woff?9wf6kc') format('woff'),
    url('fonts/icomoon.svg?9wf6kc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */
@font-face {
  font-family: "beacharoundIcons";
  src: url("{{ BEACHAROUND_FONTS_FOLDER }}/beacharoundIcons.eot?9wf6kc");
  src: url("{{ BEACHAROUND_FONTS_FOLDER }}/beacharoundIcons.eot?9wf6kc#iefix") format("embedded-opentype"), url("{{ BEACHAROUND_FONTS_FOLDER }}/beacharoundIcons.ttf?9wf6kc") format("truetype"), url("{{ BEACHAROUND_FONTS_FOLDER }}/beacharoundIcons.woff?9wf6kc") format("woff"), url("{{ BEACHAROUND_FONTS_FOLDER }}/beacharoundIcons.svg?9wf6kc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  font-family: "beacharoundIcons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-qrcode:before {
  content: "\e900";
}

.icon-alert:before {
  content: "\e901";
}

.icon-animals:before {
  content: "\e902";
}

.icon-arrival:before {
  content: "\e903";
}

.icon-arrow-bottom:before {
  content: "\e904";
}

.icon-arrow-double-broken-h:before {
  content: "\e905";
}

.icon-arrow-double-broken-v:before {
  content: "\e906";
}

.icon-arrow-double-h:before {
  content: "\e907";
}

.icon-arrow-double-hl:before {
  content: "\e908";
}

.icon-arrow-double-hr:before {
  content: "\e909";
}

.icon-arrow-double-v:before {
  content: "\e90a";
}

.icon-arrow-double-vb:before {
  content: "\e90b";
}

.icon-arrow-double-vt:before {
  content: "\e90c";
}

.icon-arrow-left:before {
  content: "\e90d";
}

.icon-arrow-right:before {
  content: "\e90e";
}

.icon-arrow-top:before {
  content: "\e90f";
}

.icon-baby:before {
  content: "\e910";
}

.icon-beach:before {
  content: "\e911";
}

.icon-bottom:before {
  content: "\e912";
}

.icon-calendar:before {
  content: "\e913";
}

.icon-cash:before {
  content: "\e914";
}

.icon-checked:before {
  content: "\e915";
}

.icon-circle-minus:before {
  content: "\e916";
}

.icon-circle-plus:before {
  content: "\e917";
}

.icon-city:before {
  content: "\e918";
}

.icon-cross:before {
  content: "\e919";
}

.icon-dashboard:before {
  content: "\e91a";
}

.icon-delete:before {
  content: "\e91b";
}

.icon-denied:before {
  content: "\e91c";
}

.icon-departure:before {
  content: "\e91d";
}

.icon-download:before {
  content: "\e91e";
}

.icon-edit:before {
  content: "\e91f";
}

.icon-experience:before {
  content: "\e920";
}

.icon-fiscal-receipt:before {
  content: "\e921";
}

.icon-fitness:before {
  content: "\e922";
}

.icon-food:before {
  content: "\e923";
}

.icon-fun:before {
  content: "\e924";
}

.icon-geolocal:before {
  content: "\e925";
}

.icon-headt-full:before {
  content: "\e926";
}

.icon-heart:before {
  content: "\e927";
}

.icon-hotel:before {
  content: "\e928";
}

.icon-interest-point:before {
  content: "\e929";
}

.icon-left:before {
  content: "\e92a";
}

.icon-mail:before {
  content: "\e92b";
}

.icon-menu:before {
  content: "\e92c";
}

.icon-minus:before {
  content: "\e92d";
}

.icon-money:before {
  content: "\e92e";
}

.icon-next-arrow:before {
  content: "\e92f";
  color: #c8ced3;
}

.icon-not-visible:before {
  content: "\e930";
}

.icon-paypal:before {
  content: "\e931";
}

.icon-percentage:before {
  content: "\e932";
}

.icon-plus:before {
  content: "\e933";
}

.icon-prev-arrow:before {
  content: "\e934";
  color: #c8ced3;
}

.icon-print:before {
  content: "\e935";
}

.icon-public-beach:before {
  content: "\e936";
}

.icon-receipt:before {
  content: "\e937";
}

.icon-relax:before {
  content: "\e938";
}

.icon-right:before {
  content: "\e939";
}

.icon-search:before {
  content: "\e93a";
}

.icon-settings-gear:before {
  content: "\e93b";
}

.icon-settings:before {
  content: "\e93c";
}

.icon-settings2:before {
  content: "\e93d";
}

.icon-spot:before {
  content: "\e93e";
}

.icon-star-full:before {
  content: "\e93f";
}

.icon-star:before {
  content: "\e940";
}

.icon-stats:before {
  content: "\e941";
}

.icon-time:before {
  content: "\e942";
}

.icon-top:before {
  content: "\e943";
}

.icon-user:before {
  content: "\e944";
}

.icon-visible:before {
  content: "\e945";
}